import React, { useState, useEffect } from "react";
import { Dropdown, Button, Offcanvas } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import {
  login,
  getUserAddress,
  getWeb,
  GetChainId,
} from "../Web3/Web3function";
import axios from "axios";

const url = "https://api-dex.gcex.lt";

const Header = ({
  Supply,
  closeprice,
  usdmmkrate,
  currentMMk,
  gcusdm,
  priceRatio,
  displaycurrentmmk,
  fixedMarketCap,
}) => {
  const [address, setAddress] = useState();
  const [p2pprice, setP2Pprice] = useState(0);
  const [chain, setChain] = useState();
  const [added, setAdded] = useState(0);
  const [usdmtousd, setUSDMtoUSD] = useState(0);
  const [govt, setGovt] = useState(0);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    connect();
    axios.get("https://api-dex.gcex.lt/gcsdetails").then(function (response) {
      setUSDMtoUSD(response.data.XAUS.USDM2USDT);
    });
  }, []);

  const connect = async () => {
    const data1 = await login();
    if (data1) {
      setAddress(data1);
    } else {
      const data = await getUserAddress();
      setAddress(data);
    }
    const id = await GetChainId();
    setChain(id);

    const added = await axios.get(`${url}/gcsdetails`)
      .then(function (response) {
        return response.data.MMK.addjusted;
      })
      .catch(function (error) {
        console.log(error);
      });
    setAdded(added);

    const govt = await axios.get(`${url}/gcsdetails`)
      .then(function (response) {
        return  response.data.MMK.GOVT;
      })
      .catch(function (error) {
        console.log(error);
      });
    setGovt(govt);
  };

  window.ethereum.on("accountsChanged", (accounts) => {
    window.address = accounts[0];
    setAddress(accounts[0]);
  });
  window.ethereum.on("chainChanged", (accounts) => {
    window.location.reload();
  });

  const slicing = (add) => {
    const first = add.slice(0, 4);
    const second = add.slice(38);
    return first + "..." + second;
  };

  const changeNetwork = async (chainId) => {
    if (window.ethereum) {
      const Web3 = getWeb();
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(chainId) }],
        });
      } catch (e) {}
    }
  };

  return (
    <div style={{ backgroundColor: "#000" }}>
      <div className="d-flex justify-content-between  p-4 m">
        <img
          src="https://cdn2.bttcdn.com/2e4add5483abf2ff/e2bc39a2d59c627c24ff83406d75d1a6.png"
          style={{ maxWidth: "90px" }}
        />
        <FaBars onClick={handleShow} className="togglebutton" />

        <div className="switchclass">
          <button className="btn btn-warning me-3">
            <a
              href="https://dxsale.app/app/v3_3/dxlockview?id=0&add=0x3d2bb1f7ab5d64C3917DbE03d37843421A42e0cD&type=tokenlock&chain=BSC"
              target="_black"
              style={{
                textDecoration: "none",
                color: "#000",
                marginLeft: "10px",
              }}
            >
              LOCKED RESERVED
            </a>
          </button>
          <button className="btn btn-warning me-3" onClick={() => connect()}>
            {address ? slicing(address) : "Connect"}
          </button>
          <Dropdown>
            <Dropdown.Toggle variant="warning" id="dropdown-basic">
            {chain === 56
                  ? "BINANCE SMART CHAIN"
                  : "Unsupported Network"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => changeNetwork(56)}>
                BINANCE SMART CHAIN
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={() => changeNetwork(97)}>
                BSC TESTNET
              </Dropdown.Item>
              <Dropdown.Item onClick={() => changeNetwork(3)}>
                ROPSTEN
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="divider"></div>
      <div className="p-2 d-flex justify-content-evenly flex-column flex-md-row ">
        <span className=" headata p-2 rounded my-2 topbutn diffbg">
          Total Supply: {Number(Supply / 10 ** 18).toFixed(0)} USDm
        </span>
        <span className="text-uppercase headata p-2 rounded my-2 topbutn">
          GCS to USDT: <b>${Number(closeprice).toFixed(4)}</b>
        </span>
        <span className="text-uppercase headata p-2 rounded my-2 topbutn">
          GCS MARKET CAP: <b>${(Number(closeprice) * 5000000).toFixed(0)}</b>
        </span>
      </div>
      <div className="p-2 d-flex justify-content-evenly flex-column flex-md-row ">
        <span className="text-uppercase headata p-2 rounded my-2 topbutn">
          USD to MMK (Govt.): <b>{govt}</b>
        </span>
        {/* <span className="text-uppercase headata p-2 rounded my-2 topbutn">
          USD to MMK: {usdmmkrate}
        </span> */}
        <span className="text-uppercase headata p-2 rounded my-2 topbutn">
          MMK Binance P2P PRICE: <b>{displaycurrentmmk}</b>
        </span>
        <span className="text-uppercase headata p-2 rounded my-2 topbutn">
          GCS TO USDm:
          <b>
            {" "}
            {isNaN(Number(gcusdm).toFixed(5)) ? "0" : Number(gcusdm).toFixed(5)}
          </b>
        </span>
      </div>
      <div className="p-3 d-flex justify-content-evenly flex-column flex-md-row">
        <span className="text-uppercase headata p-2 rounded my-2 topbutn">
          USDM TO USDT: <b>${Number(closeprice / gcusdm).toFixed(5)}</b>
        </span>
        <span className="text-uppercase headata p-2 rounded my-2 topbutn">
          USDM MARKET CAP: <b>${Number(fixedMarketCap).toFixed(0)}</b>
        </span>
        <span className="text-uppercase headata p-2 rounded my-2 topbutn diffbg">
          GCS Reserves: $ 755030
          {/* <b>{Number(
            Number(Supply / 10 ** 18).toFixed(0) / Number(gcusdm)
          ).toFixed(0)}</b> */}
        </span>
      </div>


      <div className="effectivemmk">
        <p>
          Effective MMK Rate:{" "}
          {(
            (1 - Number(closeprice / gcusdm)) * Number(currentMMk) +
            govt
          ).toFixed(0)}
        </p>
      </div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="switchclass" id="switcharea">
            <button className="btn btn-warning me-3">
              <a
                href="https://dxsale.app/app/v3_3/dxlockview?id=0&add=0x3d2bb1f7ab5d64C3917DbE03d37843421A42e0cD&type=tokenlock&chain=BSC"
                target="_black"
                style={{
                  textDecoration: "none",
                  color: "#000",
                  marginLeft: "10px",
                }}
              >
                LOCKED RESERVED
              </a>
            </button>
            <button className="btn btn-warning me-3" onClick={() => connect()}>
              {address ? slicing(address) : "Connect"}
            </button>
            <Dropdown>
              <Dropdown.Toggle variant="warning" id="dropdown-basic">
                {chain === 97
                  ? "BINANCE SMART CHAIN"
                  : "Unsupported Network"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => changeNetwork(56)}>
                  BINANCE SMART CHAIN
                </Dropdown.Item>
                {/* <Dropdown.Item onClick={() => changeNetwork(97)}>
                  BSC TESTNET
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeNetwork(3)}>
                  ROPSTEN
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Header;
