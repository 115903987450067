import { useEffect, useState } from "react";
import "./App.css";
import Header from "./Componenets/Header";
import TableCom from "./Componenets/Table";
import { USD_TEST_NET } from "./Web3/Address";
import {
  Totak_Supply,
  Mint,
  Burn,
  Add_Minter,
  Remove_Minter,
  Checking_Minter,
  PauseMintBurn,
  blacklistaddress,
  unblacklistaddress,
  rescuToken,
  getRescuer,
  getPauser,
  getBlacklister,
  unPauseMintBurn,
  MintByMaster,
  getMasterminter,
  isContractPaused,
  changeMasterMinter,
  changeRescuer,
  changePauser,
  changeBlacklister,
  getOwner,
} from "./Web3/Methods";
import { login, getUserAddress, GetChainId } from "./Web3/Web3function";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const user = "USDM";
const passward = "usdm@123";
const url = "https://api-dex.gcex.lt";
//const onlne_database_id = '62a6a96c7569644be358b812'  //for offline data base
const onlne_database_id = "62fa5c8fd7e12e2e92d40746"; //for online data base

const error = (msg) => toast.error(msg);
const sucess = (msg) => toast.success(msg);

const App = () => {
  const [headerbtn, setHeaderbtn] = useState(0);
  const [totalS, setTotalS] = useState(0);
  const [mintamount, setMintamount] = useState(0);
  const [burnamount, setBurnAmount] = useState(0);
  const [addminteraddress, setAddminteraddress] = useState("");
  const [addminteramount, setAddminteramount] = useState(0);
  const [removeminteraddress, setRemoveminteraddress] = useState("");
  const [Checkminter, setCheckminter] = useState(false);
  const [mintererror, setMinterError] = useState(0);
  const [closeprice, setCloseprice] = useState(0);
  const [usdmmkrate, setUSDMMKRATE] = useState(0);
  const [pauser, setPause] = useState();
  const [recuer, setRecuer] = useState();
  const [address, setAddress] = useState("");
  const [sendertoken, setSendertoken] = useState("");
  const [tokenamount, setTokenamount] = useState(0);
  const [blacklist, setBlacklist] = useState("");
  const [unblacklist, setUnBlacklist] = useState("");
  const [currentMMk, setCurrentMMk] = useState(0);
  const [LastMMk, setLastMMk] = useState(0);
  const [msg, setMsg] = useState();
  const [gcusdm, setGcusdm] = useState(0);
  const [priceRatio, setPriceRation] = useState(0);
  const [blacklister, setBlacklister] = useState();
  const [masterminterm, setMasterMinter] = useState("");
  const [contractpaused, setContractPaused] = useState(false);
  const [rescuetoken, setRescueToken] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [pass, setPass] = useState();
  const [worngpass, setWorngPass] = useState("");
  const [showmsg, setShowMsg] = useState(true);
  const [displaycurrentmmk, setDisplaycurrentmmk] = useState(0);
  const [displaylastmmk, setDisplaylastmmk] = useState(0);
  const [newMMK, setNewMMK] = useState(0);
  const [AdjustMMk, setAdjustMMk] = useState(0);
  const [owner, setOwner] = useState("");
  const [updatePauser, setUpdatePauser] = useState("");
  const [updateRescuer, setUpdateRescuer] = useState("");
  const [updateBlackLister, setUpdateBlackLister] = useState("");
  const [updateMasterMinter, setUpdateMasterMinter] = useState("");
  const [gcssupply, setGCSSupply] = useState(0);
  const [usdtsupply, setUSDTSupply] = useState(0);
  const [usdcsupply, setUSDCSupply] = useState(0);
  const [govt, setGovt] = useState();
  const [result, setResult] = useState(0);
  const [toBurnMint, setToBurnMint] = useState(undefined);
  const [fixedMarketCap, setFixedMarketCap] = useState(0);
  const [username, setUsername] = useState("");

  const date = Date.now();

  useEffect(() => {
    try {
      const init = async () => {
        try {
          await login();
          await APIs();
          await Calculation();
          const contractowner = await getOwner();
          setOwner(contractowner);
          const data = await Totak_Supply();
          setTotalS(data);
          const masterminter = await getMasterminter();
          setMasterMinter(masterminter);
          const paused = await isContractPaused();
          setContractPaused(paused);
          const add = await getUserAddress();
          setAddress(add);
          const cheking = await Checking_Minter();
          setCheckminter(cheking);
          setMinterError(cheking);
          const res = await getRescuer();
          setRecuer(res);
          const pau = await getPauser();
          setPause(pau);
          const bal = await getBlacklister();
          setBlacklister(bal);
        } catch (error) {}
      };
      init();

      setInterval(() => {
        if (true) {
          // showValue(Math.abs(msg), totalS);
        }
      }, 10000);
    } catch (error) {
      console.log(error);
    }
  }, [LastMMk, address, window.address]);

  const showValue = async () => {
    axios
      .get(`${url}/values`)
      .then((res) => {
        if (res.data.length > 0) {
          if (res.data[0].time > 0) {
            if (date > res.data[0].time + 3600000) {
              setShowMsg(true);
              // console.log("time is over, can mint now")
            } else {
              setShowMsg(false);
              // console.log("time not over,can not mint now")
            }
          } else {
            setShowMsg(true);
            // console.log("no time, can mint now")
          }
        } else {
          setShowMsg(true);
          // console.log("to time, can mint now")
        }
      })
      .catch(console.error);
  };

  const init2 = async () => {
    await login();
    await APIs();
    await Calculation();
    const contractowner = await getOwner();
    setOwner(contractowner);
    const data = await Totak_Supply();
    setTotalS(data);
    const masterminter = await getMasterminter();
    setMasterMinter(masterminter);
    const paused = await isContractPaused();
    setContractPaused(paused);
    const add = await getUserAddress();
    setAddress(add);
    const cheking = await Checking_Minter();
    setCheckminter(cheking);
    setMinterError(cheking);
    const res = await getRescuer();
    setRecuer(res);
    const pau = await getPauser();
    setPause(pau);
    const bal = await getBlacklister();
    setBlacklister(bal);
  };

  window.ethereum.on("accountsChanged", (accounts) => {
    window.address = accounts[0];
    setAddress(accounts[0]);
  });

  const dataFill = async (method, amount, hash, TS) => {
    var d = new Date().toLocaleString();
    await send_Value(TS);
    axios
      .post(`${url}/table`, {
        Method: method,
        Amount: amount,
        Date: d,
        Hash: hash,
      })
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });

    // var d = new Date().toLocaleString();
    // let decodestoredevents = [];
    // let data = {};
    // const storedata = JSON.parse(window.localStorage.getItem("transaction"));
    // if (storedata == null) {
    //   // console.log('run')
    //   window.localStorage.setItem(
    //     "transaction",
    //     JSON.stringify(decodestoredevents)
    //   );
    // }
    // const storedata2 = JSON.parse(window.localStorage.getItem("transaction"));
    // data.method = method;
    // data.amount = amount;
    // data.time = d;
    // data.hash = hash;
    // storedata2.push(data);
    // storedata2.reverse();
    // window.localStorage.setItem("transaction", JSON.stringify(storedata2));
  };

  const APIs = async () => {
    await axios.get("https://api-dex.gcex.lt/gcsdetails", {})
      .then(function (response) {
        setCloseprice(response.data.GCS.GCSTOUSDT);
      })
      .catch(function (error) {
        console.log(error);
      });

    await axios.get("https://api-dex.gcex.lt/gcsdetails")
      .then(function (response) {
        setUSDMMKRATE(response.data.MMK.GOVT);
      })
      .catch(function (error) {
        console.log(error);
      });

    const added = await axios.get(`${url}/gcsdetails`)
      .then(function (response) {
        return response.data.MMK.addjusted;
      })
      .catch(function (error) {
        console.log(error);
      });


    await axios.get(`${url}/gcsdetails`)
      .then(function (response) {
        if (true) {
          setDisplaycurrentmmk(response.data.MMK.MMKprice);
          setDisplaylastmmk(response.data.MMK.MMKprice);
          setCurrentMMk(Number(response.data.MMK.MMKprice) + Number(added));
          setLastMMk(Number(response.data.MMK.MMKprice) + Number(added));
          // if(response.data[0].price < 2050){
          //   setCurrentMMk(response.data[0].price);
          // setLastMMk(response.data[1].price);
          // }
          // else{
          // setCurrentMMk(Number(response.data[0].price)+Number(added));
          // setLastMMk(Number(response.data[1].price)+Number(added));
          // }
          // setCurrentMMk(response.data[0].price);
          // setLastMMk(response.data[1].price);
        }
      })
      .catch(function (error) {
        console.log("Error", error);
      });
  };

  const Mint_token = async (amount) => {
    try {
      const data1 = await Mint(amount);
      console.log(data1);
      if (data1.status) {
        const data = await Totak_Supply();
        setTotalS(data);
        sucess("Token Minted Successfully");
        dataFill("Mint", amount, data1.transactionHash, data / 10 ** 18);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const MasterMinting = async (amount) => {
    try {
      const data1 = await MintByMaster(amount);
      console.log(data1);
      if (data1.status) {
        const data = await Totak_Supply();
        setTotalS(data);
        sucess("Token Minted Successfully");
        dataFill("Mint", amount, data1.transactionHash, data / 10 ** 18);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Burn_token = async (amount) => {
    try {
      const data1 = await Burn(amount);
      console.log(data1);
      if (data1.status) {
        const data = await Totak_Supply();
        setTotalS(data);
        sucess("Token Burned Successfully");
        dataFill("Burn", amount, data1.transactionHash, data / 10 ** 18);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const send_Value = async (totalSupply) => {
    axios
      .put(`${url}/valuechange/${onlne_database_id}`, {
        time: date,
        totalSupply: totalSupply,
        GCSMARKETCAP: closeprice * 5000000,
        USDMMARKETCAP: priceRatio * totalSupply,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const add_minter = async () => {
    try {
      const data = await Add_Minter(addminteraddress, addminteramount);
      if (data.status) {
        sucess("Minter Added Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const remove_minter = async () => {
    try {
      const data = await Remove_Minter(removeminteraddress);
      if (data.status) {
        sucess("Minter Removed Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Calculation = async () => {
    const govt = await axios.get(`${url}/gcsdetails`)
      .then(function (response) {
        return response.data.MMK.GOVT;
      })
      .catch(function (error) {
        console.log(error);
      });

    const ResultOLDGCStoUSDm = (LastMMk * closeprice) / govt;
    const ResultOLDPRICERATIO = closeprice / ResultOLDGCStoUSDm.toFixed(5);
    const ResultNEWGCStoUSDm = (currentMMk * closeprice) / govt;

    setGcusdm(ResultNEWGCStoUSDm);

    const ResultNEWPRICERATIO = closeprice / ResultNEWGCStoUSDm.toFixed(5);
    setPriceRation(ResultNEWPRICERATIO);

    const resultratio =
      (ResultOLDPRICERATIO - ResultNEWPRICERATIO) / ResultOLDPRICERATIO;
    setMsg(resultratio);

    const fixedMcap = 755030 * closeprice;
    let newSupply =
      fixedMcap / Number(closeprice / ResultNEWGCStoUSDm).toFixed(5);
    newSupply = newSupply - Number(totalS) / 10 ** 18;
    setToBurnMint(newSupply);
    setFixedMarketCap(fixedMcap);
  };

  const pause = async () => {
    try {
      const data = await PauseMintBurn();
      if (data.status) {
        sucess("Paused Successfully");
        const paused = await isContractPaused();
        setContractPaused(paused);
      } else {
        error("Could not Paused");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const unpause = async () => {
    try {
      const data = await unPauseMintBurn();
      if (data.status) {
        sucess("Paused Successfully");
        const paused = await isContractPaused();
        setContractPaused(paused);
      } else {
        error("Could not Paused");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addtoblacklist = async () => {
    try {
      const data = await blacklistaddress(blacklist);
      if (data.status) {
        sucess("Blacklisted Successfully");
      } else {
        error("Could not Blacklist");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removetoblacklist = async () => {
    try {
      const data = await unblacklistaddress(unblacklist);
      if (data.status) {
        sucess("Removed Successfully");
      } else {
        error("Could not Blacklist");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const RescueToken = async () => {
    try {
      const data = await rescuToken(rescuetoken, sendertoken, tokenamount);
      if (data.status) {
        sucess("Successfully");
      } else {
        error("Failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateRes = async () => {
    try {
      const data = await changeRescuer(updateRescuer);
      if (data.status) {
        sucess("Successfully");
      } else {
        error("Failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updatePas = async () => {
    try {
      const data = await changePauser(updatePauser);
      if (data.status) {
        sucess("Successfully");
      } else {
        error("Failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateBlack = async () => {
    try {
      const data = await changeBlacklister(updateBlackLister);
      if (data.status) {
        sucess("Successfully");
      } else {
        error("Failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateMaster = async () => {
    try {
      const data = await changeMasterMinter(updateMasterMinter);
      if (data.status) {
        sucess("Successfully");
      } else {
        error("Failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateNewMMK = async (data) => {
    try {
      axios.put(`${url}/valuechange/${onlne_database_id}`, {
          addMMk: data,
          govt: govt,
        })
        .then(async (res) => {
          console.log(res);
          sucess("Adjusted Successfull");
          await APIs();
          await Calculation();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  setInterval(() => {
    // const data = 0;
    // const storedata = JSON.parse(window.localStorage.getItem("sugestion"));
    // if (storedata == null) {
    //   window.localStorage.setItem("sugestion", JSON.stringify(data));
    // }
    // if (currentMMk > 0 && totalS > 0 && closeprice > 0) {
    //   const stored = JSON.parse(window.localStorage.getItem("sugestion"));
    //   console.log(stored);
    //   // if(stored != Math.abs(Number((msg * totalS) / 10 ** 18).toFixed(0))){
    //   // }
    // }
  }, 3000);

  useEffect(() => {
    const data = document.cookie;
    // if(data.includes(passward)){
    //   setIsLogin(true)
    // }
  });

  const rememberme = () => {
    document.cookie = pass;
  };

  const Login = (tab) => {
    if (tab == passward && username == user) {
      console.log(username == user, tab == passward);
      setIsLogin(true);
    } else {
      setWorngPass("Wrong password");
    }
  };

  const calsy = async () => {
    const usdm = await axios
      .get("https://apigctech.ap.ngrok.io/usdm")
      .then(function (response) {
        return response.data[0].USDM2USDT;
      });
    const close = await axios
      .get("https://sapi.gcex.lt/v1/market/tickers", {})
      .then(function (response) {
        return Number(response.data.data["GCS/USDT"].close);
      });
    const res =
      (Number(gcssupply) * close + Number(usdtsupply) + Number(usdcsupply)) /
      usdm;
    setResult(res);
  };

  return (
    <>
      {(() => {
        if (isLogin === true) {
          return (
            <div className="App">
              <Header
                Supply={totalS}
                closeprice={closeprice}
                usdmmkrate={usdmmkrate}
                currentMMk={currentMMk}
                gcusdm={gcusdm}
                priceRatio={priceRatio}
                displaycurrentmmk={displaycurrentmmk}
                fixedMarketCap={fixedMarketCap}
              />
              <div
                className="d-flex justify-content-center nav345"
                style={{ backgroundColor: "#000", marginBottom: "100px" }}
              >
                <span
                  className={`fw-bold fs-5 text-white  ${
                    headerbtn == 0 ? "herader-btn" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => setHeaderbtn(0)}
                >
                  Minter
                </span>
                <span
                  className={`fw-bold ms-4 fs-5 text-white  ${
                    headerbtn == 1 ? "herader-btn" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => setHeaderbtn(1)}
                >
                  Master Minter
                </span>
                <span
                  className={`fw-bold ms-4 fs-5 text-white  ${
                    headerbtn == 2 ? "herader-btn" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => setHeaderbtn(2)}
                >
                  Pauser
                </span>
                <span
                  className={`fw-bold ms-4 fs-5 text-white  ${
                    headerbtn == 3 ? "herader-btn" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => setHeaderbtn(3)}
                >
                  Rescuer
                </span>
                <span
                  className={`fw-bold ms-4 fs-5 text-white  ${
                    headerbtn == 4 ? "herader-btn" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => setHeaderbtn(4)}
                >
                  Blacklist
                </span>
                <span
                  className={`fw-bold ms-4 fs-5 text-white  ${
                    headerbtn == 5 ? "herader-btn" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => setHeaderbtn(5)}
                >
                  Owner
                </span>
                {/* <span
                  className={`fw-bold ms-4 fs-5 text-white  ${
                    headerbtn == 6 ? "herader-btn" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => setHeaderbtn(6)}
                >
                  Calculate
                </span> */}
              </div>

              {/* FOR RESPONSIVE */}
              <div className="mint-box">
                { headerbtn == 0 ? (
                  <>
                    {/* {mintererror == false ? (
                      <div className="text-danger mt-1 fw-bold">
                        You are not Minter
                      </div>
                    ) : (
                      ""
                    )} */}
                    <div className="container ">
                      {/* <div className="row justify-content-center">
                        <div className="col-md-4 text-white mb-3">
                          MMK price 1 hour ago
                          <br />
                          <div className="mt-1 price-box">
                            <input
                      type="number"
                      className="border-secondary border-1"
                      value={LastMMk}
                      onChange={(e) => setLastMMk(e.target.value)}
                      disabled
                    />
                            <span className="price">{displaylastmmk}</span>
                          </div>
                        </div>
                        <div className="col-md-4 text-white">
                          Current MMK price <br />
                          <div className="mt-1 price-box">
                            <input
                      type="number"
                      className="border-secondary border-1"
                      value={currentMMk}
                      onChange={(e) => setCurrentMMk(e.target.value)}
                      disabled
                    />
                            <span className="price">{displaycurrentmmk}</span>
                          </div>
                        </div>
                      </div> */}

                      {toBurnMint ? (
                        <div className="row mt-2">
                          <div className="col">
                            {toBurnMint > 0 ? (
                              <span className="suggestionMint fw-bold  p-2">
                                Suggested Mint {Number(toBurnMint).toFixed(0)}{" "}
                                USDm
                              </span>
                            ) : (
                              <span className=" fw-bold  suggestionBurn p-2">
                                Suggested Burn {Number(toBurnMint).toFixed(0)}{" "}
                                USDm
                              </span>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div
                      className="container p-5  justify-content-around mt-5"
                      style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
                    >
                      {toBurnMint > 0 ? (
                        <div className="box1">
                          <br />
                          <button
                            className={`rounded mt-2 ${
                              Checkminter
                                ? "btn btn-warning"
                                : "btn-secondary disabled"
                            }`}
                            disabled={`${!showmsg ? "disabled" : ""}`}
                            onClick={() => Mint_token(Number(toBurnMint))}
                          >
                            Mint
                          </button>
                        </div>
                      ) : (
                        <div className="box1 ">
                          <button
                            className={`rounded mt-2  ${
                              Checkminter
                                ? "btn btn-warning"
                                : "btn-secondary disabled"
                            }`}
                            onClick={() => Burn_token(Number(toBurnMint))}
                            disabled={`${!showmsg ? "disabled" : ""}`}
                          >
                            Burn
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                ) : address == masterminterm && headerbtn == 1 ? (
                  <>
                    <div
                      className="container p-2  justify-content-around mt-2"
                      style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
                    >
                      <div>
                        <div className="box1">
                          <input
                            placeholder="Amount"
                            className="border-secondary border-1"
                            value={mintamount}
                            onChange={(e) => setMintamount(e.target.value)}
                          />
                          <br />
                          <button
                            className={`rounded mt-2   ${
                              Checkminter
                                ? "btn btn-warning"
                                : "btn-secondary disabled"
                            }`}
                            onClick={() => MasterMinting(mintamount)}
                          >
                            Master Mint
                          </button>
                        </div>
                        <div className="box1 ">
                          <input
                            placeholder="Amount"
                            className="border-secondary border-1"
                            value={burnamount}
                            onChange={(e) => setBurnAmount(e.target.value)}
                          />
                          <br />
                          <button
                            className={`rounded mt-2  ${
                              Checkminter
                                ? "btn btn-warning"
                                : "btn-secondary disabled"
                            }`}
                            onClick={() => Burn_token(burnamount)}
                          >
                            Burn
                          </button>
                        </div>
                      </div>
                      <div className="box1 ">
                        <input
                          placeholder="address"
                          className="border-secondary border-1"
                          value={addminteraddress}
                          onChange={(e) => setAddminteraddress(e.target.value)}
                        />
                        <br />
                        <input
                          className="mt-1 border-secondary border-1"
                          placeholder="amount"
                          value={addminteramount}
                          onChange={(e) => setAddminteramount(e.target.value)}
                        />
                        <br />
                        <button
                          className={`rounded mt-2  ${
                            true ? "btn btn-warning" : "btn-secondary disabled"
                          }`}
                          onClick={() => add_minter()}
                        >
                          Add Minter
                        </button>
                      </div>

                      <div className="box1 ">
                        <input
                          placeholder="address"
                          className="border-secondary border-1"
                          value={removeminteraddress}
                          onChange={(e) =>
                            setRemoveminteraddress(e.target.value)
                          }
                        />
                        <br />
                        <button
                          className={`rounded mt-2  ${
                            true ? "btn btn-warning" : "btn-secondary disabled"
                          }`}
                          onClick={() => remove_minter()}
                        >
                          Remove Minter
                        </button>
                      </div>
                      <div className="box1 ">
                        <input
                          placeholder="MMK Adjust"
                          className="border-secondary border-1 mb-1"
                          value={newMMK}
                          onChange={(e) => setNewMMK(e.target.value)}
                        />
                        <br />
                        <input
                          placeholder="MMK govt adjust"
                          className="border-secondary border-1"
                          value={govt}
                          onChange={(e) => setGovt(e.target.value)}
                        />
                        <br />
                        <button
                          className={`rounded mt-2  ${
                            true ? "btn btn-warning" : "btn-secondary disabled"
                          }`}
                          onClick={() => updateNewMMK(newMMK)}
                        >
                          Adjust MMK
                        </button>
                      </div>
                    </div>
                  </>
                ) : address == pauser && headerbtn == 2 ? (
                  <>
                    {/* {address == pauser ? (
                      ""
                    ) : (
                      <div className="text-danger mt-1 fw-bold">
                        You are not Pauser
                      </div>
                    )} */}
                    {!contractpaused ? (
                      ""
                    ) : (
                      <div className="text-danger mt-1 fw-bold">
                        Contract is Paused
                      </div>
                    )}
                    <div
                      className="container p-2  justify-content-around mt-2"
                      style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
                    >
                      <div className="box1">
                        <button
                          className={`rounded mt-2  ${
                            true ? "btn btn-warning" : "btn-secondary disabled"
                          }`}
                          onClick={() => pause()}
                        >
                          Pause
                        </button>
                      </div>

                      <div className="box1">
                        <button
                          className={`rounded mt-2  ${
                            true ? "btn btn-warning" : "btn-secondary disabled"
                          }`}
                          onClick={() => unpause()}
                        >
                          Resume
                        </button>
                      </div>
                    </div>
                  </>
                ) : address == recuer && headerbtn == 3 ? (
                  <>
                    {/* {address == recuer ? (
                      ""
                    ) : (
                      <div className="text-danger mt-1 fw-bold">
                        You are not Recuer
                      </div>
                    )} */}
                    <div
                      className="container p-2  justify-content-around mt-2"
                      style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
                    >
                      <div className="box1 ">
                        <input
                          placeholder="Token address"
                          className="border-secondary border-1"
                          value={rescuetoken}
                          onChange={(e) => setRescueToken(e.target.value)}
                        />
                        <br />
                        <input
                          placeholder="Receiver address"
                          className="border-secondary border-1"
                          value={sendertoken}
                          onChange={(e) => setSendertoken(e.target.value)}
                        />
                        <br />
                        <input
                          className="mt-1 border-secondary border-1"
                          placeholder="amount"
                          value={tokenamount}
                          onChange={(e) => setTokenamount(e.target.value)}
                        />
                        <br />
                        <button
                          className={`rounded mt-2  ${
                            true ? "btn btn-warning" : "btn-secondary disabled"
                          }`}
                          onClick={() => RescueToken()}
                        >
                          Rescue
                        </button>
                      </div>
                    </div>
                  </>
                ) : address == blacklister && headerbtn == 4 ? (
                  <>
                    {/* {address == blacklister ? (
                      ""
                    ) : (
                      <div className="text-danger mt-1 fw-bold">
                        You are not Blacklister
                      </div>
                    )} */}
                    <div
                      className="container p-2  justify-content-around mt-2"
                      style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
                    >
                      <div className="box1 ">
                        <input
                          className="mt-1 border-secondary border-1"
                          placeholder="address"
                          value={blacklist}
                          onChange={(e) => setBlacklist(e.target.value)}
                        />
                        <br />
                        <button
                          className={`rounded mt-2  ${
                            true ? "btn btn-warning" : "btn-secondary disabled"
                          }`}
                          onClick={() => addtoblacklist()}
                        >
                          Blacklist
                        </button>
                      </div>

                      <div className="box1 ">
                        <input
                          placeholder="address"
                          className="border-secondary border-1"
                          value={unblacklist}
                          onChange={(e) => setUnBlacklist(e.target.value)}
                        />
                        <br />
                        <button
                          className={`rounded mt-2  ${
                            true ? "btn btn-warning" : "btn-secondary disabled"
                          }`}
                          onClick={() => removetoblacklist()}
                        >
                          Remove from Blacklist
                        </button>
                      </div>
                    </div>
                  </>
                ) : address == owner && headerbtn == 5 ? (
                  <>
                    <div
                      className="container p-2  justify-content-around mt-2"
                      style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
                    >
                      <div className="box1 ">
                        <input
                          className="mt-1 border-secondary border-1"
                          placeholder="address"
                          value={updatePauser}
                          onChange={(e) => setUpdatePauser(e.target.value)}
                        />
                        <br />
                        <button
                          className={`rounded mt-2  ${
                            true ? "btn btn-warning" : "btn-secondary disabled"
                          }`}
                          onClick={() => updatePas()}
                        >
                          Set Pauser
                        </button>
                      </div>

                      <div className="box1 ">
                        <input
                          placeholder="address"
                          className="border-secondary border-1"
                          value={updateBlackLister}
                          onChange={(e) => setUpdateBlackLister(e.target.value)}
                        />
                        <br />
                        <button
                          className={`rounded mt-2  ${
                            true ? "btn btn-warning" : "btn-secondary disabled"
                          }`}
                          onClick={() => updateBlack()}
                        >
                          Set Blacklist
                        </button>
                      </div>

                      <div className="box1 ">
                        <input
                          placeholder="address"
                          className="border-secondary border-1"
                          value={updateRescuer}
                          onChange={(e) => setUpdateRescuer(e.target.value)}
                        />
                        <br />
                        <button
                          className={`rounded mt-2  ${
                            true ? "btn btn-warning" : "btn-secondary disabled"
                          }`}
                          onClick={() => updateRes()}
                        >
                          Set Rescuer
                        </button>
                      </div>

                      <div className="box1 ">
                        <input
                          placeholder="address"
                          className="border-secondary border-1"
                          value={updateMasterMinter}
                          onChange={(e) =>
                            setUpdateMasterMinter(e.target.value)
                          }
                        />
                        <br />
                        <button
                          className={`rounded mt-2  ${
                            true ? "btn btn-warning" : "btn-secondary disabled"
                          }`}
                          onClick={() => updateMaster()}
                        >
                          Set Master Minter
                        </button>
                      </div>
                    </div>
                  </>
                ) : headerbtn == 10 ? (
                  <>
                    <div
                      className="container p-2  justify-content-around mt-2"
                      style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
                    >
                      <div className="box1 ">
                        <p className="m-0 text-light text-start">GCS Supply</p>
                        <input
                          className="mt-1 border-secondary border-1"
                          placeholder="GCS"
                          value={gcssupply}
                          onChange={(e) => setGCSSupply(e.target.value)}
                        />
                      </div>

                      <div className="box1 ">
                        <p className="m-0 text-light text-start">USDT Supply</p>
                        <input
                          placeholder="USDT"
                          className="border-secondary border-1"
                          value={usdtsupply}
                          onChange={(e) => setUSDTSupply(e.target.value)}
                        />
                      </div>

                      <div className="box1 ">
                        <p className="m-0 text-light text-start">USDC Supply</p>
                        <input
                          placeholder="USDC"
                          className="border-secondary border-1"
                          value={usdcsupply}
                          onChange={(e) => setUSDCSupply(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        className="btn btn-warning "
                        onClick={() => calsy()}
                      >
                        Calculate
                      </button>
                    </div>
                    {result > 0 ? (
                      <div className="mt-5 text-center mb-5">
                        <p className="text-light">
                          Supply allowed to be minted{" "}
                          {Number(result).toFixed(5)}
                        </p>
                        <button
                          className={`rounded mt-2 btn btn-warning`}
                          onClick={() => MasterMinting(result)}
                        >
                          Master Mint
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}

                {/* {headerbtn == 0 || headerbtn == 1 ? (
                  <div>
                    <TableCom />
                  </div>
                ) : (
                  ""
                )} */}

                <div>
                  <Toaster url={url} />
                </div>
              </div>

              <div className="foot-container">
                <footer className="footer">
                  <p> &copy; GCEX</p>
                </footer>
              </div>
            </div>
          );
        } else {
          return (
            <>
              <div className="p-2 d-flex justify-content-evenly flex-column flex-md-row logindata">
                <span className="text-uppercase headata p-2 rounded my-2 topbutn">
                  GCS to USDT: <b>${Number(closeprice).toFixed(4)}</b>
                </span>
                <span className="text-uppercase headata p-2 rounded my-2 topbutn">
                  GCS MARKET CAP:{" "}
                  <b>${(Number(closeprice) * 5000000).toFixed(0)}</b>
                </span>
              </div>
              <div className="container  login-container text-light d-flex justify-content-center align-items-center">
                <form className="login">
                  {/* Email input */}
                  <div className="form-outline mb-4">
                    <label className="form-label" for="form2Example1">
                      Username
                    </label>
                    <input
                      type="text"
                      id="form2Example1"
                      className="form-control"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>

                  {/* Password input */}
                  <div className="form-outline mb-4">
                    <label className="form-label" for="form2Example2">
                      Password
                    </label>
                    <input
                      type="password"
                      id="form2Example2"
                      className="form-control"
                      value={pass}
                      onChange={(e) => setPass(e.target.value)}
                    />
                    <span className="text-danger">{worngpass}</span>
                    <p className="m-0 mt-1">
                      Remember Me{" "}
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        onClick={() => rememberme()}
                      />
                    </p>
                  </div>

                  {/* 2 column grid layout for inline styling */}
                  {/* <div className="row mb-4">
                  <div className="col">
            
                    <a href="#">Forgot password?</a>
                  </div>
                </div> */}

                  {/* Submit button */}
                  <button
                    type="button"
                    className="btn btn-warning btn-block mb-4"
                    onClick={() => Login(pass)}
                  >
                    Sign in
                  </button>
                </form>
              </div>
            </>
          );
        }
      })()}
    </>
  );
};

export default App;

export const APIURL = () => {
  return url;
};
