import { getContract, getUserAddress, towie } from "./Web3function";
import { USDm } from "./Contracts/MyanmarUSD"
import { USD_TEST_NET } from "./Address";

// updateRescuer updateMasterMinter updateBlacklister  _updatePauser
export const Totak_Supply =async()=>{
   try {
    const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
    const data = await contract.methods.totalSupply().call();
    
    return Number(data)
   } catch (error) {
       console.log(error)
   }
}

export const getOwner =async()=>{
    try {
        const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
        const data = await contract.methods.owner().call();
        console.log("Data woner",data)
        return data;
    } catch (error) {
        
    }
}

export const changeMasterMinter =async(address)=>{
    try {
        const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
        const data = await contract.methods.updateMasterMinter(address).send({from : await getUserAddress()});
        return data;
    } catch (error) {
        console.log(error)
    }
}
export const changeRescuer =async(address)=>{
    try {
        const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
        const data = await contract.methods.updateRescuer(address).send({from : await getUserAddress()});
        return data;
    } catch (error) {
        console.log(error)
    }
}
export const changePauser =async(address)=>{
    try {
        const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
        const data = await contract.methods._updatePauser(address).send({from : await getUserAddress()});
        return data;
    } catch (error) {
        console.log(error)
    }
}
export const changeBlacklister =async(address)=>{
    try {
        const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
        const data = await contract.methods.updateBlacklister(address).send({from : await getUserAddress()});
        return data;
    } catch (error) {
        console.log(error)
    }
}


export const getMasterminter = async()=>{
    try {
        const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
        const data = await contract.methods.masterMinter().call();
        return data;
        } catch (error) {
            console.log(error)
        }

}

export const isContractPaused = async()=>{
    try {
        const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
        const data = await contract.methods.paused().call();
        return data;
        } catch (error) {
            console.log(error)
        }

}

export const getPauser =async()=>{
    try {
     const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
     const data = await contract.methods.pauser().call();
     return data;
    } catch (error) {
        console.log(error)
    }
 }
 export const getRescuer = async()=>{
    try {
     const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
     const data = await contract.methods.rescuer().call();
     return data;
    } catch (error) {
        console.log(error)
    }
 }
 export const getBlacklister =async()=>{
    try {
     const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
     const data = await contract.methods.blacklister().call();
     return data;
    } catch (error) {
        console.log(error)
    }
 }
 export const MintByMaster =async(amount)=>{
    try {
        const a = await towie(amount)
        console.log("Mint amount",a)
        const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
        const data = await contract.methods.mintByMaster(await getMasterminter(),a).send({from : await getUserAddress()});
        return data;
       } catch (error) {
           console.log(error)
       }
 }

export const PauseMintBurn =async(amount)=>{
    try {
     const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
     const data = await contract.methods._pause().send({from : await getUserAddress()});
     return data;
    } catch (error) {
        console.log(error)
    }
 }
 export const unPauseMintBurn =async(amount)=>{
    try {
     const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
     const data = await contract.methods._unpause().send({from : await getUserAddress()});
     return data;
    } catch (error) {
        console.log(error)
    }
 }
 export const blacklistaddress =async(address)=>{
    try {
     const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
     const data = await contract.methods.blacklist(address).send({from : await getUserAddress()});
     return data;
    } catch (error) {
        console.log(error)
    }
 }
 export const unblacklistaddress =async(address)=>{
    try {
     const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
     const data = await contract.methods.unBlacklist(address).send({from : await getUserAddress()});
     return data;
    } catch (error) {
        console.log(error)
    }
 }

 export const rescuToken =async(address,to,amount)=>{
    try {
    const a = await towie(amount)
     const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
     const data = await contract.methods.rescueERC20(address,to,a).send({from : await getUserAddress()});
     return data;
    } catch (error) {
        console.log(error)
    }
 }

export const Mint =async(amount)=>{
   try {
    const a = await towie(amount)
    console.log("Mint amount",a)
    const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
    const data = await contract.methods.mint(USD_TEST_NET.Master,a).send({from : await getUserAddress()});
    return data;
   } catch (error) {
    console.log(error)
   }
}

export const Burn =async(amount)=>{
    let b
    try {
    b = Math.abs(amount)
    console.log("Burn amount",b)
    const a = await towie(b)
    console.log(a)
    const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
    const data = await contract.methods.burn(a).send({from : await getUserAddress()});
    return data;
    } catch (error) {
        console.log(error)
    }
}


export const Add_Minter =async(address,amount)=>{
    try {
        const a = await towie(amount)
    console.log(a)
    const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
    const data = await contract.methods.configureMinter(address,a).send({from : await getUserAddress()});
    return data;
    } catch (error) {
        console.log(error)
    }
}


export const Remove_Minter =async(address)=>{
    try {
        const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
    const data = await contract.methods.removeMinter(address).send({from : await getUserAddress()});
    return data;
    } catch (error) {
        console.log(error)
    }
}


export const Checking_Minter =async()=>{
    try {
    const contract = await getContract(USDm, USD_TEST_NET.Stable_Currency)
    const data = await contract.methods.isMinter(await getUserAddress()).call();
    return data;
    } catch (error) {
        console.log(error)
    }
}